<template>
    <div class="error-page" style="margin-top:100px">
        <div class="content-con">
            <div class="text-con">
                <h4>{{ code }}</h4>
                <h5>{{ desc }}</h5>
            </div>
            <back-btn-group class="back-btn-group"></back-btn-group>
        </div>
    </div>
</template>

<script>
import './error.less'
import backBtnGroup from './back-btn-group.vue'
export default {
    name: 'error_content',
    components: {
        backBtnGroup
    },
    props: {
        code: String,
        desc: String,
        src: String
    }
}
</script>
